import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button/Button';
import { colors, Div, radius, spaces } from '../../styles/style';
import { Paragraph, Title } from '../Text/Text';
import BubbleModalButton from '../Button/BubbleModalButton';

const AlertBanner = ({
  showAlert,
  title,
  subtitle,
  onClick,
  icon,
  iconColor,
  buttonText,
  background,
  borderColor,
  bubbleModal = false,
  modalProps
}) => {
  return showAlert ? (
    <Div
      align="start"
      gap={spaces.space1}
      $fullWidth
      padding={spaces.space2}
      $backgroundColor={background || colors.orange50}
      $borderRadius={radius.radius1}
      border={`1px solid ${borderColor || colors.orange100}`}
    >
      <FontAwesomeIcon
        icon={icon || faCircleExclamation}
        color={iconColor || colors.orange500}
        size="lg"
        style={{ marginTop: spaces.space0 }}
      />
      <Div direction="column" align="start" gap={spaces.space1} $fullWidth>
        <Title color={colors.neutral600}>{title}</Title>
        {subtitle && <Paragraph type="small">{subtitle}</Paragraph>}
      </Div>
      {buttonText &&
        (bubbleModal ? (
          <BubbleModalButton {...modalProps}>
            <Button type="primary" align="start" onClick={onClick}>
              {buttonText}
            </Button>
          </BubbleModalButton>
        ) : (
          <Button type="primary" align="start" onClick={onClick}>
            {buttonText}
          </Button>
        ))}
    </Div>
  ) : null;
};

AlertBanner.propTypes = {
  showAlert: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.instanceOf(Object),
  iconColor: PropTypes.string,
  buttonText: PropTypes.string,
  background: PropTypes.string,
  borderColor: PropTypes.string,
  bubbleModal: PropTypes.bool,
  modalProps: PropTypes.instanceOf(Object)
};

export default AlertBanner;
