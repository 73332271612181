import {
  attachmentButton,
  cancelInstallment,
  cancelPayment,
  installmentSlipBank,
  paidInstallment,
  undoReconciliation,
  unpaidInstallment
} from './_dropdown';
import { store } from '../../config/redux-store';
import { getPaymentLabel } from '../../helpers/payment';
import { hasPermission } from '../../../routes/Common/PrivateRoute';

const payment = ({ payment: _payment, idRefurbish, paymentStatuses, isOpportunity, hasPermissionVP }) => {
  const { paymentStatus, isVobiPay, isCharge, splitId } = _payment || {};
  const isDraft = paymentStatus?.id === paymentStatuses?.draft;
  const hasEditPermission = !isVobiPay || (isVobiPay && hasPermissionVP);
  const isCancelled = _payment?.paymentStatus?.id === paymentStatuses?.cancelled;

  const result = [
    ...(!isDraft
      ? [
          {
            id: 'view-payment',
            externalAction: true,
            modal: 'viewPaymentDetails',
            label: 'Ver detalhes',
            idRefurbish,
            idPayment: _payment?.id,
            splitId: _payment?.splitId,
            isCharge,
            billType: _payment?.billType
          }
        ]
      : []),
    ...(!isCancelled && isCharge && hasEditPermission
      ? [
          {
            id: 'edit-payment',
            externalAction: true,
            modal: 'editPayment',
            label: 'Editar pagamento',
            idRefurbish,
            idPayment: _payment?.id,
            isCharge
          }
        ]
      : []),

    ...(!isDraft && !isCancelled && isCharge
      ? [
          {
            id: 'share-payment',
            label: 'Compartilhar pagamento',
            modal: 'sharePayment',
            modalParams: { itemId: _payment?.id, idRefurbish: _payment?.idRefurbish, isOpportunity, model: 'payment' }
          }
        ]
      : [])
  ];
  if (!isCancelled && hasEditPermission && isCharge) result.push(cancelPayment());

  if (!splitId) {
    result.push({
      id: 'duplicate-payment',
      externalAction: true,
      label: 'Duplicar',
      idRefurbish,
      idPaymentToDuplicate: _payment?.id,
      billType: _payment.billType,
      isCharge: _payment.isCharge
    });
  }
  return result;
};

const paymentChildren = ({
  installment,
  payment: _payment,
  idRefurbish,
  refurbishType,
  valuesChanged,
  handleSave,
  isCharge,
  billType,
  touched,
  onItemClick,
  drawerZindex,
  isRecurrence,
  isVobiPay
}) => {
  const { setup, authReducer } = store.getState();
  const { bankSlipUrl, idReconciliation } = installment;

  const { installmentStatuses, billType: billTypeEnum, paymentStatuses, plans, permissions } = setup.enums;
  const { user } = authReducer;
  const hasNfsePermission = hasPermission(user, ['emitNfse'], plans, permissions);

  const action = {
    income: 'viewIncomeDetails',
    expense: 'viewExpenseDetails'
  };
  const label = {
    income: 'Duplicar receita',
    expense: 'Duplicar despesa'
  };
  const unpaid =
    (!isVobiPay && installment?.idInstallmentStatus === installmentStatuses.paid) ||
    (isVobiPay && installment?.idInstallmentStatus === installmentStatuses.paidManually);

  const canCancel = isVobiPay && installment?.idInstallmentStatus === installmentStatuses.pendingPayment;
  const isCancelled = installment?.idInstallmentStatus === installmentStatuses.cancelled;

  const installmentPaid = installmentStatuses.allPaid.includes(installment?.idInstallmentStatus);

  const __payment = installment?.payment || _payment;
  const viewPaymentLabel = `Ver ${getPaymentLabel({
    ...__payment,
    billTypeEnum
  })}`;

  const canEmitNfse = hasNfsePermission && __payment?.billType === 'income' && __payment?.ownBusiness && !isCancelled;

  const _undoReconciliation = idReconciliation && !isVobiPay;

  const result = [
    ...(installment?.idInstallmentStatus === installmentStatuses.pendingPayment &&
    __payment?.idPaymentStatus !== paymentStatuses.draft
      ? [{ ...paidInstallment, modalParams: { installment, valuesChanged, handleSave, isCharge, billType, touched } }]
      : []),
    ...(unpaid && !idReconciliation ? [unpaidInstallment] : []),
    ...(!installment?.idReceipt && installmentPaid
      ? [
          {
            label: 'Emitir recibo',
            verb: 'post',
            actionKey: 'receipt',
            id: 'generate-pay-slip',
            pathOptions: '/receipt',
            displayToast: 'Operação realizada com sucesso!'
          }
        ]
      : []),
    ...(installment?.idReceipt && installmentPaid
      ? [
          {
            label: 'Visualizar recibo',
            redirectTo: `/rc/${installment?.idReceipt}`,
            target: '_blank',
            id: 'view-pai-slip'
          }
        ]
      : []),
    ...(canEmitNfse
      ? [
          {
            label: 'Emitir nota fiscal',
            id: 'emitNf',
            modal: 'emitNfseDrawer',
            modalParams: {
              idPayment: __payment?.id,
              value: installment?.price,
              date: installment?.dueDate,
              open: true
            }
          }
        ]
      : []),
    ...(bankSlipUrl && !isCancelled ? [installmentSlipBank] : []),
    ...(!isCancelled ? [attachmentButton] : []),
    ...(isRecurrence || _payment?.recurrenceId
      ? []
      : [
          {
            label: 'Detalhes da parcela',
            id: 'edit',
            modal: 'editPaymentModal',
            modalParams: {
              installment,
              valuesChanged,
              handleSave,
              isCharge,
              billType,
              refurbishType,
              handleRedirect: onItemClick,
              zIndex: drawerZindex
            }
          }
        ]),
    ...(_undoReconciliation ? [undoReconciliation(installment)] : [])
  ];

  if (__payment) {
    result.push({
      id: 'view-payment',
      externalAction: true,
      modal: __payment.isCharge ? 'viewPaymentDetails' : action[__payment.billType],
      label: viewPaymentLabel,
      idRefurbish,
      idPayment: __payment.id,
      billType: __payment.billType,
      isCharge: __payment.isCharge,
      splitId: __payment?.splitId
    });
  }

  if (__payment && !__payment?.splitId) {
    result.push({
      id: 'duplicate-payment',
      externalAction: true,
      label: __payment.isCharge ? 'Duplicar pagamento' : label[__payment.billType],
      idRefurbish,
      idPaymentToDuplicate: __payment.id,
      billType: __payment.billType,
      isCharge: __payment.isCharge
    });
  }

  if (canCancel) {
    result.push(cancelInstallment);
  }

  return result;
};
const markAsPaid = [
  {
    button: 'markAsPaid',
    buttonParams: {
      text: true,
      children: 'Marcar como pago'
    }
  }
];

export { payment, paymentChildren, markAsPaid };
