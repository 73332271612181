import React from 'react';
import * as Yup from 'yup';
import { Divider } from 'antd';
import {
  idReference,
  idReferenceNotNull,
  requiredAddress,
  requiredNumber,
  simpleName,
  text,
  validateDocument,
  priceValidationCurrency
} from '../schema';
import { Subtitle } from '../../../components/Text/Text';
import { Div, spaces } from '../../../styles/style';
import { renderPriceOrPercentage } from '../../helpers/helper';
import formatBdi from '../../helpers/formatBdi';

const { zipcode, street, state, number } = requiredAddress;

const typeItems = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' }
];

const sendNfseSchema = Yup.object().shape({
  idCompanyEntity: idReferenceNotNull,
  idCompanyCustomer: idReference,
  customerDoc: validateDocument,
  businessName: Yup.string().when('personType', {
    is: 'pj',
    then: simpleName,
    otherwise: text
  }),
  zipcode,
  street,
  neighborhood: simpleName,
  numberAddress: number,
  state,
  cityCode: idReference,
  value: priceValidationCurrency,
  idServiceProfile: idReference,
  serviceState: state,
  serviceCityCode: requiredNumber,
  serviceDescription: simpleName,
  workCode: Yup.string().when('isConstructionService', {
    is: true,
    then: simpleName,
    otherwise: text
  }),
  ARTCode: Yup.string().when('isConstructionService', {
    is: true,
    then: simpleName,
    otherwise: text
  }),
  netValue: number
});

const sendNfseMapping = ({
  readOnly,
  setCompanyEntitySelected,
  setCompanyCustomerSelected,
  setServiceProfileSelected,
  openServiceDrawer,
  openCustomCreate
}) => ({
  idCompanyCustomer: _companyCustomer,
  personType: _type,
  idServiceProfile: _serviceProfile,
  isConstructionService: _isConstructionService,
  serviceState: _serviceState,
  idCompanyEntity,
  state: _state
}) => {
  const isPj = _type === 'pj';
  return {
    subtitle1: {
      type: 'custom',
      Component: () => <Subtitle>Dados do negócio</Subtitle>,
      xs: 24,
      md: 24
    },
    idCompanyEntity: {
      name: 'Empresa',
      type: 'select',
      required: true,
      readOnly,
      model: 'companyEntity',
      firstAsDefault: !idCompanyEntity,
      placeholder: 'Selecione',
      firstByProps: { key: 'NFSe', value: true },
      saveFullObject: ({ value, label, color, where, ...values }) => {
        return setCompanyEntitySelected({
          ...values,
          id: value || values?.id,
          name: label || values?.name
        });
      },
      extraPropsOnOptions: [
        'email',
        'crt',
        'certificateDueDate',
        'cnpj',
        'id',
        'phone',
        'serialNumber',
        'rpsNumber',
        'NFSe',
        'NFe',
        'withCertificate',
        'withCredentials',
        'certificateDueDate',
        'state',
        'cityCode'
      ],
      xs: 24,
      md: 8
    },
    cnpj: {
      name: 'CNPJ',
      type: 'cnpj',
      readOnly: true,
      xs: 24,
      md: 8
    },
    name: {
      name: 'Nome fantasia',
      type: 'text',
      readOnly: true,
      xs: 24,
      md: 8
    },
    crt: {
      name: 'Código de Regime Tributário',
      type: 'select',
      dataType: 'crtList',
      readOnly: true,
      xs: 24,
      md: 8
    },
    companyEntityEmail: {
      name: 'E-mail',
      type: 'text',
      readOnly: true,
      xs: 24,
      md: 8
    },
    companyEntityPhone: {
      name: 'Celular',
      type: 'phone',
      readOnly: true,
      xs: 24,
      md: 8
    },
    divider2: {
      type: 'custom',
      Component: () => <Divider style={{ margin: `${spaces.space1} 0` }} />,
      xs: 24,
      md: 24
    },
    subtitle3: {
      type: 'custom',
      Component: () => <Subtitle>Dados do cliente</Subtitle>,
      xs: 24,
      md: 24
    },
    idCompanyCustomer: {
      name: 'Cliente',
      type: 'select',
      required: true,
      readOnly,
      model: 'companyCustomer',
      placeholder: 'Selecione o cliente',
      saveFullObject: ({ value, label, color, where, ...values }) =>
        setCompanyCustomerSelected({
          ...values,
          id: value,
          name: label
        }),
      extraPropsOnOptions: [
        'id',
        'name',
        'businessName',
        'email',
        'doc',
        'personType',
        'phone',
        'zipcode',
        'street',
        'number',
        'complement',
        'state',
        'city',
        'neighborhood'
      ],
      xs: 24,
      md: 8
    },
    personType: {
      name: 'Tipo',
      type: 'radioSwitch',
      required: true,
      readOnly,
      items: typeItems,
      defaultValue: 'pj',
      disabled: !_companyCustomer,
      width: '100%',
      xs: 24,
      md: 8
    },
    customerDoc: {
      name: isPj ? 'CNPJ' : 'CPF',
      type: isPj ? 'cnpj' : 'cpf',
      required: true,
      disabled: !_companyCustomer,
      readOnly,
      placeholder: `Informe o ${isPj ? 'CNPJ' : 'CPF'}`,
      xs: 24,
      md: 8
    },
    ...(isPj && {
      businessName: {
        name: 'Razão Social',
        type: 'text',
        required: true,
        disabled: !_companyCustomer,
        readOnly,
        placeholder: 'Razão Social',
        xs: 24,
        md: 16
      }
    }),
    email: {
      name: 'E-mail',
      type: 'text',
      placeholder: 'Informe o e-mail',
      disabled: !_companyCustomer,
      readOnly,
      nameTooltip: 'O e-mail será utilizado para notificar seu cliente na criação da nota fiscal.',
      xs: 24,
      md: 8
    },
    phone: {
      name: 'Telefone',
      type: 'phone',
      placeholder: '(19) 99999-9999',
      disabled: !_companyCustomer,
      readOnly,
      xs: 24,
      md: 8
    },
    div: {
      type: 'custom',
      Component: () => <Div> </Div>,
      xs: 24,
      md: isPj ? 16 : 8
    },
    zipcode: {
      name: 'CEP',
      type: 'zipCode',
      required: true,
      disabled: !_companyCustomer,
      readOnly,
      placeholder: 'Informe o CEP',
      xs: 24,
      md: 8
    },
    street: {
      name: 'Rua',
      type: 'text',
      required: true,
      disabled: !_companyCustomer,
      readOnly,
      placeholder: 'Rua',
      xs: 24,
      md: 8
    },
    numberAddress: {
      name: 'Número',
      type: 'text',
      required: true,
      disabled: !_companyCustomer,
      readOnly,
      placeholder: 'Número',
      xs: 24,
      md: 4
    },
    complement: {
      name: 'Complemento',
      type: 'text',
      disabled: !_companyCustomer,
      readOnly,
      placeholder: 'Complemento',
      xs: 24,
      md: 4
    },
    neighborhood: {
      name: 'Bairro',
      type: 'text',
      required: true,
      disabled: !_companyCustomer,
      readOnly,
      placeholder: 'Bairro',
      xs: 24,
      md: 8
    },

    cityCode: {
      name: 'Cidade',
      type: 'select',
      required: true,
      model: 'city',
      modelOptions: { where: { state: _state } },
      prevValidateFields: ['state'],
      placeholder: 'Cidade',
      disabled: !_state || !_companyCustomer,
      parent: { name: 'state' },
      xs: 24,
      md: 8
    },
    state: {
      name: 'Estado',
      dataType: 'states',
      type: 'select',
      required: true,
      placeholder: 'Estado',
      disabled: !_companyCustomer,
      xs: 24,
      md: 8
    },

    divider3: {
      type: 'custom',
      Component: () => <Divider style={{ margin: `${spaces.space2} 0 ${spaces.space1}` }} />,
      xs: 24,
      md: 24
    },
    subtitle4: {
      type: 'custom',
      Component: () => <Subtitle>Dados da emissão</Subtitle>,
      xs: 24,
      md: 24
    },
    date: {
      name: 'Data de competência',
      type: 'date',
      readOnly,
      dateFormat: 'DD/MM/YYYY',
      xs: 24,
      md: 6
    },
    value: {
      name: `Valor do serviço prestado`,
      type: 'currency',
      required: true,
      readOnly,
      placeholder: 'R$ 0,00',
      xs: 24,
      md: 6
    },
    ...(!openServiceDrawer.open
      ? {
          idServiceProfile: {
            name: 'Serviço prestado',
            type: 'select',
            required: true,
            readOnly,
            model: 'serviceProfile',
            allowCustomOptions: true,
            openCustomCreate,
            disabled: !idCompanyEntity,
            prevValidateFields: ['idCompanyEntity'],
            modelOptions: { where: { idCompanyEntity } },
            placeholder: 'Selecione o serviço',
            saveFullObject: ({ value, label, color, where, ...values }) =>
              setServiceProfileSelected({
                ...values,
                id: value,
                name: label
              }),
            extraPropsOnOptions: ['id', 'name', 'iss', 'pis', 'cofins', 'csll', 'inss', 'ir', 'description'],
            xs: 24,
            md: 12
          }
        }
      : {}),
    retainISS: {
      name: 'Reter ISS',
      type: 'select',
      required: true,
      readOnly,
      dataType: 'boolStatus',
      disabled: !_companyCustomer,
      xs: 12,
      md: 6
    },
    taxISS: {
      name: 'Alíquota ISS',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      readOnly,
      nameTooltip: 'Sugerido com base em seu serviço. Em caso de retenção, consulte seu contador.',
      disabled: !_serviceProfile,
      xs: 12,
      md: 6
    },
    serviceState: {
      name: 'Estado',
      type: 'select',
      required: true,
      disabled: !idCompanyEntity,
      readOnly,
      dataType: 'states',
      placeholder: 'Selecione',
      xs: 24,
      md: 4
    },
    serviceCityCode: {
      name: 'Município de prestação do serviço',
      type: 'select',
      required: true,
      disabled: !idCompanyEntity,
      readOnly,
      model: 'city',
      modelOptions: { where: { state: _serviceState } },
      prevValidateFields: ['state'],
      placeholder: 'Selecione',
      parent: { name: 'serviceState' },
      xs: 24,
      md: 8
    },
    serviceDescription: {
      name: `Descrição do serviço`,
      type: 'textarea',
      required: true,
      disabled: !_serviceProfile,
      readOnly,
      placeholder: 'Discriminação do serviço que será exibido no XML/PDF da nota fiscal.',
      xs: 24,
      md: 24
    },
    isConstructionService: {
      type: 'checkbox',
      readOnly,
      customLabel: 'Serviço relacionado à construção civil',
      disabled: !_serviceProfile,
      xs: 24,
      md: 24
    },
    ...(_isConstructionService && {
      workCode: {
        name: 'Código da obra',
        type: 'text',
        required: true,
        readOnly,
        placeholder: 'Código',
        xs: 12,
        md: 6
      },
      ARTCode: {
        name: 'Código da A.R.T',
        type: 'text',
        required: true,
        readOnly,
        placeholder: 'Código',
        xs: 12,
        md: 6
      }
    }),
    divider4: {
      type: 'custom',
      Component: () => <Divider style={{ margin: `${spaces.space2} 0 ${spaces.space1}` }} />,
      xs: 24,
      md: 24
    },
    subtitle5: {
      type: 'custom',
      Component: () => <Subtitle>Impostos retidos e outras deduções</Subtitle>,
      xs: 24,
      md: 24
    },
    taxCSLL: {
      name: 'CSLL',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      readOnly,
      xs: 12,
      md: 6
    },
    valueCSLL: {
      name: 'Valor CSLL',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly,
      xs: 12,
      md: 6
    },
    taxIR: {
      name: 'IR',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      readOnly,
      xs: 12,
      md: 6
    },
    valueIR: {
      name: 'Valor IR',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly,
      xs: 12,
      md: 6
    },
    taxCOFINS: {
      name: 'COFINS',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      readOnly,
      xs: 12,
      md: 6
    },
    valueCOFINS: {
      name: 'Valor COFINS',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly,
      xs: 12,
      md: 6
    },
    taxPIS: {
      name: 'PIS',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      readOnly,
      xs: 12,
      md: 6
    },
    valuePIS: {
      name: 'Valor PIS',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly,
      xs: 12,
      md: 6
    },
    taxINSS: {
      name: 'INSS',
      type: 'text',
      mask: 'number',
      placeholder: '0,00%',
      formatter: val => (val ? `${renderPriceOrPercentage(val)}` : ''),
      parseFunc: val => formatBdi(val),
      readOnly,
      xs: 12,
      md: 6
    },
    valueINSS: {
      name: 'Valor INSS',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly,
      xs: 12,
      md: 6
    },
    otherDeduction: {
      name: 'Outras deduções',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly,
      xs: 12,
      md: 6
    },
    divider5: {
      type: 'custom',
      Component: () => <Divider style={{ margin: `${spaces.space2} 0 ${spaces.space1}` }} />,
      xs: 24,
      md: 24
    },
    subtitle6: {
      type: 'custom',
      Component: () => <Subtitle>Valor da nota</Subtitle>,
      xs: 24,
      md: 24
    },
    total: {
      name: 'Valor bruto',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly: true,
      nameTooltip: 'Valor total do serviço antes de descontos e tributos.',
      xs: 8,
      md: 6
    },
    discounts: {
      name: 'Descontos',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly: true,
      nameTooltip: 'Soma do valor dos impostos e outras deduções.',
      xs: 8,
      md: 6
    },
    netValue: {
      name: 'Valor líquido',
      type: 'currency',
      placeholder: 'R$ 0,00',
      readOnly: true,
      nameTooltip: 'Valor total com os impostos já descontados.',
      xs: 8,
      md: 6
    }
  };
};

export { sendNfseMapping, sendNfseSchema };
