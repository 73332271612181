import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';

const BubbleModalButton = ({
  children,
  feature = '',
  page = 'modal-upgrade',
  title = 'upgradeModal',
  width = 1048,
  height = 630
}) => {
  const [showModal, setShowModal] = useState(false);

  const Content = () =>
    Array.isArray(children)
      ? children
      : React.Children.map(children, child => {
          return React.cloneElement(child, {
            onClick: () => setShowModal(true),
            style: { ...(child.props?.style || {}), cursor: 'pointer' }
          });
        });

  return (
    <>
      <Content>{children}</Content>
      <Modal open={showModal} onClose={() => setShowModal(false)} width={width} hideFooter noPadding>
        <iframe
          width="100%"
          height={height}
          title={title}
          src={`${process.env.REACT_APP_BUBBLE_URL}/${page}/${feature}`}
        />
      </Modal>
    </>
  );
};

BubbleModalButton.propTypes = {
  children: PropTypes.instanceOf(Object),
  feature: PropTypes.string,
  page: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default BubbleModalButton;
