import React from 'react';

import { faPenToSquare, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import ItemDetailButton from '../../../components/Button/ItemDetailButton';
import { ButtonGroup } from '../../../components/Button/ItemDetailButton.styled';
import { colors, Div, spaces } from '../../../styles/style';
import { Paragraph } from '../../../components/Text/Text';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import { renderPriceOrPercentage } from '../../helpers/helper';

const columns = ({ onEdit, onDelete }) => {
  const allColumns = [
    {
      title: 'Código de tributação',
      dataIndex: 'municipalCode',
      key: 'municipalCode',
      width: 200
    },
    {
      title: 'Nome do serviço',
      dataIndex: 'name',
      ellipsis: true,
      key: 'name',
      render: (val, row) => {
        const { isDefault } = row;
        return (
          <Div gap={spaces.space1}>
            <Paragraph $ellipsisClamp={2}>{val}</Paragraph>{' '}
            <TooltipIcon text="Serviço padrão" color={colors.primary500} icon={isDefault ? faStar : null} />
          </Div>
        );
      }
    },
    {
      title: 'ISS',
      dataIndex: 'iss',
      key: 'iss',
      width: 120,
      render: val => renderPriceOrPercentage(val)
    },
    {
      title: 'INSS',
      dataIndex: 'inss',
      key: 'inss',
      width: 120,
      render: val => renderPriceOrPercentage(val)
    },
    {
      title: 'Ações',
      key: 'action',
      width: 120,
      render: (_, row) => {
        return (
          <ButtonGroup onClick={e => e.stopPropagation()}>
            <ItemDetailButton
              titleTooltip="Editar serviço"
              onClick={() => onEdit(row)}
              iconColor={colors.primary500}
              icon={faPenToSquare}
            />
            <ItemDetailButton
              titleTooltip="Excluir serviço"
              onClick={() => onDelete({ open: true, id: row?.id })}
              iconColor={colors.red500}
              icon={faTrashCan}
            />
          </ButtonGroup>
        );
      }
    }
  ];

  return allColumns;
};

export { columns };
